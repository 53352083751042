.promo-area {
    padding: 120px 0 90px;

    @media(max-width: 991px) {
        padding: 80px 0 50px;
    }
}

.promo {
    border-radius: 10px;
    overflow: hidden;
    position: relative;
    max-width: 450px;
    margin: 0 auto 30px;

    img {
        width: 100%;
    }

    &__content {
        position: absolute;
        top: 47px;
        left: 40px;
    }

    &__title {
        color: #fff;
        margin-bottom: 5px;
    }

    &__description {
        color: #fff;
        font-weight: 500;
    }

    &__btn {
        position: absolute;
        left: 40px;
        bottom: 40px;
        box-shadow: none;
        font-size: 15px;
        padding: 11px 21px;
        background: #fff;
        color: #0d031f;
        border: 2px solid #fff;
        font-weight: 500;

        i {
            font-size: 20px;
            vertical-align: middle;
        }

        &:hover {
            background: transparent;
            border-color: rgba(255, 255, 255, 0.302);
            
        }
    }
}

  