.genera-informes {
    padding-top: 197px;
    padding-bottom: 222px;
    overflow: hidden;

    .section-title {
        margin-bottom: 30px;
    }

    .list-items {
        margin-bottom: 50px;
    }

    .shape-bg {
        left: auto;
        top: 0;
        right: 0;

    }

    &-two {
        padding-top: 50px;

        .section-title {
            margin-top: 115px;
            margin-bottom: 36px;
        }

        .list-items {
            margin-bottom: 50px;
        }
    }
}


.informes-feature-image {
    position: absolute;
    right: 75px;
    top: 143px;

    .image-one {
        max-width: 720px;
    }

    .image-two {
        position: absolute;
        right: 150px;
        bottom: -140px;
        max-width: 720px;
        ;

    }
}

.genera-informes-two {
    .scroll-circle {
        top: 60px;
    }
}

.genera-informes-three {
    padding-top: 242px;
    position: relative;

    .shape-bg {
        left: auto;
        right: 0;
        top: 40px;
    }

    .list-items {
        margin-bottom: 50px;
    }

    .section-title {
        margin-bottom: 35px;
    }
}


@media (max-width: 1680px) {
    .informes-feature-image {
        position: absolute;
        right: 50px;
        top: 125px;

        .image-one {
            max-width: 620px;
        }

        .image-two {
            position: absolute;
            right: 130px;
            bottom: -140px;

            img {
                max-width: 620px !important;
            }

        }
    }
}

@media (max-width: 1600px) {
    .genera-informes {
        padding-bottom: 120px;

        .shape-bg {
            max-width: 500px;
        }
    }

    .informes-feature-image {
        top: 80px;
    }
}

@media (max-width: 1440px) {
    .informes-feature-image {
        position: absolute;
        right: 25px;
        top: 125px;

        .image-one {
            max-width: 550px;
        }

        .image-two {
            position: absolute;
            right: 100px;
            bottom: -140px;

            img {
                max-width: 550px !important;
            }

        }
    }
}

@media (max-width: 1280px) {
    .genera-informes {
        padding-bottom: 120px;

        .shape-bg {
            max-width: 400px;
        }
    }

    .informes-feature-image {
        top: 70px;
    }


}

@media (max-width: 1200px) {
    .informes-feature-image {
        position: absolute;
        right: 25px;
        top: 100px;

        .image-one {
            max-width: 450px;
        }

        .image-two {
            position: absolute;
            right: 70px;
            bottom: -140px;

            img {
                max-width: 450px !important;
            }

        }
    }

    .genera-informes {
        padding-top: 140px;

        .shape-bg {
            right: -180px;
        }
    }

    .genera-informes-three {
        padding-top: 180px;

        .shape-bg {
            width: 500px;
        }
    }

}

@media (max-width: 1024px) {
    .informes-feature-image {
        position: absolute;
        right: 25px;
        top: 75px;

        .image-one {
            max-width: 450px;
        }

        .image-two {
            position: absolute;
            right: 70px;
            bottom: -140px;
            max-width: 450px;
            ;

        }
    }

    .genera-informes .shape-bg {
        right: -180px;
    }
}

@media (max-width: 991px) {
    .genera-informes {
        padding-top: 0;
        overflow: hidden;
        padding-bottom: 80px;

        .section-title {
            margin-bottom: 20px;
            padding-top: 0;
        }

        .list-items {
            margin-bottom: 40px;
        }

        .informes-feature-image {
            position: relative;
            right: 0;
            top: 0;
            margin-top: 0;
            margin-bottom: 120px;
            padding-top: 0;
    
            .image-one {
                max-width: 450px;
            }
    
            .image-two {
                position: absolute;
                right: -70px;
                bottom: -110px;
                max-width: 450px;
                ;
    
            }
        }
    }

    .informes-feature-image {
        .image-two {
            bottom: -100px
        }
    }

    .genera-informes-two {
        padding-top: 46px;

        .section-title {
            margin-top: 71px;
            margin-bottom: 25px;
        }

        .list-items {
            margin-bottom: 40px;
        }
    }

    .genera-informes-three {
        padding-top: 0;

        .section-title {
            margin-top: 96px;

            &.style-two {
                margin-top: 100px;
            }
        }

        .informes-feature-image {
            position: relative;
            top: 0;
            right: 0;

            .image-two {
                right: auto;
                left: 70px;
            }
        }
    }

    .genera-informes .shape-bg {
        right: -180px;
    }

    .genera-informes-two .scroll-circle {
        left: -40%;
    }
}

@media (max-width: 768px) {

    .informes-feature-image {
        .image-two {
            right: -70px;
        }
    }

    // .genera-informes .informes-feature-image {
    //     padding-top: 65px;
    // }
}

@media (max-width: 576px) {
    .informes-feature-image {
        .image-two {
            left: 0;
            right: auto;

        }
    }

    .informes-feature-image {

        .image-one,
        .image-two {
            img {
                max-width: 100% !important;
            }
        }
    }
}