html {
	background-color: white;
}

body {
	font-family: $font__primary;
	font-size: 16px;
	line-height: 28px;
	color: $color__body;
	overflow-x: hidden;
}

.body-text {
	font-family: $font__primary;
	font-size: 16px;
	line-height: 28px;
	color: $color__body;
}

svg {
	display: block;
}

a,
button,
input,
textarea,
button,
select {
	transition: all 0.3s ease-in-out;
}

button {
	cursor: pointer;
	outline: 0;
}

input,
textarea,
select, .form-control {
	width: 100%;
	padding: 10px 12px;
	outline: 0;
}

.form-control {
	background: #fdfafa;
    padding: 15px 30px;
    border: 1px solid #efe7e7;
    margin-bottom: 30px;
	border-radius: 30px;
	height: 60px;

	&:focus {
		box-shadow: none;
		border-color: #efe7e7;
		background: #fff;
		box-shadow: 0px 20px 40px 0px rgba(79, 35, 35, 0.1);
	}
}

a,
a:hover {
	text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: $font__heading;
	margin-top: 0;
	font-weight: 600;
	color: $color__primary;
}


img {
	max-width: 100%;
	height: auto;
}

.pr {
	position: relative;
}

.pt-7 {
	padding-top: 70px;
}

.h-100 {
	height: 100%;
}

.container-wrap {
	max-width: 95%;
	margin: 0 auto;
	border-radius: 20px;
	position: relative;

	&.bg-color-one {
		background-color: #f6faf8;
	}

	&.bg-color-two {
		background-color: #d9e6ff;
	}

	&.bg-footer-color {
		background-color: #1d2146;
	}
}

section {
	position: relative;
	margin: 0;
}

.mw-none {
	max-width: unset !important;
}

@media (min-width: 1200px) {
	.container {
		max-width: 1200px;
	}
}

@media (min-width: 991px) {
	.pr-85 {
		padding-right: 85px;
	}

	.pl-85 {
		padding-left: 85px;
	}

	.mt-185 {
		margin-top: 185px;
	}
}

@media(max-width: 991px) {
	.mt-185 {
		margin-top: 80px;
	}
}


.section-small {
	margin-bottom: 80px;

	.title {
		font-size: 20px;
		font-weight: 500;
	}
}

.mt-40 {
	margin-top: 40px;
}

.mt-60 {
	margin-top: 60px;
}

.mt-180 {
	margin-top: 177px;

	@media(max-width: 768px) {
		margin-top: 80px;
	}
}

@media(min-width: 768px) {
	.mt-50 {
		margin-top: 50px;
	}

	.mt-20 {
		margin-top: 20px;
	}

	.mt-70 {
		margin-top: 70px;
	}
}

.section-padding {
	padding: 100px 0;
}

.no-scroll {
	overflow-y: hidden !important;
}

.container-wide {
	max-width: 1700px;
	padding: 0 15px;
	margin: 0 auto;

}

.gutters-10 > [class*=col-] {
	padding: 0 5px;

}


@media (max-width: 991px) {
	.pix-order-two {
		order: 2;
	}

	.pix-order-one {
		order: 1;
	}
}

@media (max-width: 767px) {
	.pix-order-two-md {
		order: 2;
	}

}