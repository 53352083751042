$font__primary: 'Poppins', sans-serif;
$font__heading: 'Poppins', sans-serif;

$color__theme: #fa7070;
$color__theme-2: #363795;
$color__theme-3: #fbab41;
$color__theme-4: #f97bc5;
$color__theme-5: #fd4d5c;
$color__theme-6: #12bf7c;
$color__theme-7: #f98e47;
$color__theme-8: #14aeee;
$color__primary: #2b2350;
$color__body: #797687;
$background__bg: #fdf8f8;
$background__2: #f8f7fc;

$gradient_background: linear-gradient(100deg, rgb(250, 112, 112) 0%, rgb(250, 151, 112) 100%);
$gradient_background_2: linear-gradient(-60deg, rgb(112, 82, 251) 0%, #363795 100%);
$gradient_background_3: linear-gradient(-60deg, #363795 0%, rgb(112, 82, 251) 100%);
$gradient_background_4: linear-gradient(0deg, #363795 0%, rgb(112, 82, 251) 100%);
