.review-sliders {
    background: #fcfafb;
    padding: 112px 0 120px;
    overflow: hidden;
}

#review-slider-wrapper {
    position: relative;
    #review-sliders {
        overflow: visible;

        .swiper-slide {
            

            &.swiper-slide-visible { 
                .review-slider {
                    box-shadow: 0px 30px 60px 0px rgba(48, 11, 33, 0.08);
                    opacity: 1;
                }
            }
        }
    }

    .swiper-pagination  {
        bottom: -60px;
    }
}

.review-slider {
    border-radius: 6px;
    background: #fff;    
    text-align: center;
    padding: 40px;
    height: 100%;
    min-height: 280px;
    transition: all 0.3s ease-in-out;
    opacity: 0.6;

    .review-content-inner {
        .review-score {
            height: 80px;
            width: 80px;
            line-height: 80px;
            color: $color__theme-2;
            background: #f5f1ff;
            border-radius: 50%;
            font-size: 30px;
            font-weight: 500;
            margin: 0 auto 23px;
        }

        .rating {
            margin-bottom: 22px;
        }
    }
}

@media (max-width: 991px) {
    .review-sliders {
    
        padding: 72px 0 100px;
       
    }
}