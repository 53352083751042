.joblists {
    background: #f9f8fc;
    padding: 110px 0 96px;
    background-size: cover;
    background-position: center center;

    @media(max-width: 991px) {
        padding: 70px 0 55px;
    }
}

.job__list {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    border-radius: 6px;
    background: #fff;
    box-shadow: 0px 4px 6px 0px rgba(10, 1, 47, 0.06);
    margin-bottom: 10px;
    padding: 19px 0;
    transition: all 0.3s ease-in-out;

    @media(max-width: 768px) {
        display: block;
        text-align: center;
    }

    .job-info {
        width: 60%;
        display: flex;
        flex-wrap: wrap;
        padding: 0 30px;

        @media(max-width: 991px) {
            width: 54%;
        }

        @media(max-width: 768px) {
            width: 357px; 
            margin: 0 auto 15px;
        }

        .company-logo {
            width: 40px;
            margin-right: 20px;
        }

        .info {
            flex: 1;

            .job-title {
                font-size: 20px;
                font-weight: 600;
                margin: 0;
                
                a {
                    color: $color__primary;

                    &:hover {
                        color: $color__theme-5;
                    }
                }
            }

            p {
                margin: 0;
            }
        }

    }

    .job-time {
        width: 24%;

        @media(max-width: 991px) {
            width: 16%;
        }

        @media(max-width: 768px) {
            width: 100%;
            margin-bottom: 15px;
        }
    }

    .job-location {
        width: 16%;
        padding-right: 40px;
        text-align: right;

        @media(max-width: 991px) {
            width: 30%;
        }

        @media(max-width: 768px) {
            width: 100%;
            text-align: center;
            padding-right: 0;
        }
    }

    &:hover {
        box-shadow: 0px 40px 30px 0px rgba(10, 1, 47, 0.1);
        transform: translateX(10px);
    }
}

.job-pagination-wrapper {
    margin-top: 22px;
    display: flex;
    justify-content: space-between;

    @media(max-width: 420px) {
        display: block;
    }
    .showing-result {
        .show-result {
            font-size: 14px;
            color: #75737e;
            span {
                color: $color__theme-5;
            }
        }
    }

    .job-pagination {
        margin: 0;
        padding: 0;
        list-style: none;

        li {
            display: inline-block;

            &:not(:last-child) {
                margin-right: 15px;
            }

            a {
                font-size: 14px;
                font-weight: 500;
                color: #96959e;
                display: inline-block;
               

                &:hover {
                    color: $color__theme-5
                }
            }

            &.active {
                a {
                    color: $color__theme-5;
                }
            }
        }
    }
}