.MuiSnackbar-root .MuiSnackbarContent-root {
    flex-wrap: nowrap;
}

.MuiSnackbarContent-message .MuiSvgIcon-root {
    margin-right: 15px;
}

html, body, #root, #__next {
    /* Required for pushing footer to bottom of page via flexbox */
    height: 100%;
}

code {
    background-color: rgba(27,31,35,.05);
    padding: 2px;
    font-weight: 600;
    color: inherit;
}

pre {
    line-height: 1.3em;
}

/* Header links in MDX files */
.__permalink-h {
    display: none;
}

.__target-h {
    display: block;
    /*
     * Position the target anchor shifted up to deal with our sticky header,
     * which is larger on small screens.
     */
    position: relative;
    top: -120px;
}

@media (min-width: 600px) {
    .__target-h {
        /* Position the target anchor shifted up to deal with our sticky header */
        top: -80px;
    }
}

.admonition {
    margin: 0 5% 1em 5%;
    /* margin-bottom: 1em; */
    padding: 15px 30px 15px 15px;
}

.admonition h5 {
    margin-top: 0;
    margin-bottom: 8px;
    text-transform: uppercase;
}

.admonition-icon {
    display: inline-block;
    vertical-align: text-top;
    margin-right: 0.2em;
}

.admonition-icon svg {
    display: inline-block;
    width: 22px;
    height: 22px;
    stroke-width: 0;
}

.admonition-content > :last-child {
    margin-bottom: 0;
}

.admonition-content p {
    color: #4d4d4d;
    font-size: 95%;
}

/** Customization */
.admonition-warning {
    background-color: rgba(230, 126, 34, 0.1);
    border-left: 8px solid #e67e22;
}

.admonition-warning h5 {
    color: #e67e22;
}

.admonition-warning .admonition-icon svg {
    stroke: #e67e22;
    fill: #e67e22;
}

.admonition-tip {
    background-color: rgba(46, 204, 113, 0.1);
    border-left: 8px solid #2ecc71;
}

.admonition-tip h5 {
    color: #2ecc71;
}

.admonition-tip .admonition-icon svg {
    stroke: #2ecc71;
    fill: #2ecc71;
}

.admonition-caution {
    background-color: rgba(231, 76, 60, 0.1);
    border-left: 8px solid #e74c3c;
}

.admonition-caution h5 {
    color: #e74c3c;
}

.admonition-caution .admonition-icon svg {
    stroke: #e74c3c;
    fill: #e74c3c;
}

.admonition-important {
    background-color: rgba(52, 152, 219, 0.1);
    border-left: 8px solid #3498db;
}

.admonition-important h5 {
    color: #3498db;
}

.admonition-important .admonition-icon svg {
    stroke: #3498db;
    fill: #3498db;
}

.admonition-note {
    background-color: rgba(241, 196, 15, 0.1);
    border-left: 8px solid #f1c40f;
}

.admonition-note h5 {
    color: #f1c40f;
}

.admonition-note .admonition-icon svg {
    stroke: #f1c40f;
    fill: #f1c40f;
}

.admonition-example {
    background-color: rgba(77, 68, 215, 0.1);
    border-left: 8px solid #4d44d7;
}

.admonition-example h5 {
    color: #4d44d7;
}

/* Override bootstrap's link hover */
a.MuiButton-containedPrimary:hover {
    color: #fff;
}

a.MuiButton-containedSecondary:hover {
    color: #fff;
}
