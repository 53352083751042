.service {
    padding-top: 120px;
}


.popup-videos {
    position: relative;
    max-width: 470px;

    .play-btn {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        box-shadow: none;
    }
}

.service-content {
    padding: 30px 50px 0 50px;

    .section-title {
        margin-bottom: 40px;
    }

    p {
        font-size: 18px;
        line-height: 34px;
    }
}

.featured-two-service {
    padding: 145px 0 28px;
}

@media (max-width: 991px) {
    .service {
        padding-top: 80px;
    }

    .service-content {
        padding: 0;
        margin-top: 50px;
    }
}
