.call-to-action {
    background-image: $gradient_background;
    padding: 111px 0 120px;
    overflow: hidden;

    .overlay-bg {
        position: absolute;
        right: 0;
        top: 0;
    }

    .action-content {
        position: relative;

        .title {
            color: #fff;
            font-size: 50px;
            line-height: 64px;
            font-weight: 700;
            margin-bottom: 28px;
        }

        p {
            color: #fff;
            margin-bottom: 50px;
        }

        .pix-btn {
            padding: 12px 50px;
            font-size: 16px;
            font-weight: 500;
            position: relative;
            z-index: 2;
        }

        &.style-two {
            .title {
                font-size: 40px;
                line-height: 54px;
                margin: 0;
            }
        }
    }

    &.action-padding {
        padding: 172px 0 168px;
    }

    .scroll-circle {
        right: 0;
        top: 40%;
    }
}


.signup-section {
    padding: 106px 0 111px;
    background-size: cover;
    background-position: center center;
    position: relative;
    background-image: $gradient_background_4;
    overflow: hidden;

    .bg-shape {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        img {
            position: absolute;
        }

        .shape-left {
            top: 0;
            left: 0;
        }

        .shape-right {
            bottom: 0;
            right: 0;
        }
    }
}

.signup-heading {
    .title {
        font-size: 50px;
        line-height: 64px;
        color: #fff;
        font-weight: 700;
        margin-bottom: 20px;
    }

    p {
        color: #FFF;
        margin: 0;
    }
}

// Call To Action Three
.call-to-action-three {
    background: $color__theme-4;
    padding: 230px 0 130px;
    position: relative;
    overflow: hidden;

    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 0;
        height: 0;
        border-top: 0px solid transparent;
        border-bottom: 170px solid transparent;
        border-right: 3000px solid #fff;
    }

    .bg-right-shape {
        position: absolute;
        right: 0;
        bottom: 0;
    }

    .action-content {

        .title {
            font-size: 50px;
            line-height: 60px;
            color: #fff;
            margin-bottom: 30px;
        }

        p {
            color: #fff;
            font-size: 24px;
            font-weight: 500;
            margin: 0;
            margin: 0;
        }

    }
}

.call-to-action-four {
    background: #f9f8fc;
    padding: 50px 0;
    border-top: 1px solid #e9e7f1;

    @media(max-width: 768px) {
        .text-right {
            text-align: left !important;
            margin-top: 30px;
        }
    }

    .action-content {
        .title {
            font-size: 26px;
            line-height: 40px;
            font-weight: 600;
        }

        p {
            font-size: 26px;
            line-height: 40px;
            color: #75737e;
            margin: 0;

            @media(max-width: 768px) {
                font-size: 20px;
            }
        }
    }

    .pix-btn {
        padding: 15px 43px;
    }
}

.call-to-action-hosting {
	padding: 130px 0 140px;
	// background-image: url("../../../../public/media/background/call-to-action.jpg");
	background-position: center center;
	background-size: cover;
	background-repeat: no-repeat;

	.section-title {
		margin-bottom: 38px;

	}

	.btn-outline {
		margin-left: 15px;

		@media(max-width: 380px) {
			margin-left: 0;
			margin-top: 20px;
		}
	}
}

@media (max-width: 1200px) {
    .call-to-action {
        .action-content {
            &.style-two {
                .title {
                    font-size: 36px;
                    line-height: 50px;

                }
            }
        }
    }

    .signup-heading .title {
        font-size: 40px;
        line-height: 50px;
    }
}

@media (max-width: 991px) {

    .call-to-action {
        padding: 71px 0 80px;
    }

    .call-to-action {
        &.action-padding {
            padding: 74px 0 80px;
        }
    }

    .call-to-action {
        .action-content {
            .title {
                font-size: 40px;
                line-height: 54px;
            }
        }
    }

    .call-to-action {
        .text-right {
            text-align: center !important;
        }

        .action-content {
            &.style-two {
                margin-bottom: 30px;
                text-align: center;

                .title {
                    font-size: 32px;
                    line-height: 42px;

                    br {
                        display: none;
                    }

                }
            }
        }
    }

    .call-to-action {
        .scroll-circle {
            max-width: 400px;
        }
    }

    .call-to-action-three {
        padding: 130px 0 100px;

        .action-content {
            .title {
            font-size: 36px;
            line-height: 46px;
            margin-bottom: 20px;
            }

            p {
                font-size: 20px;
            }
        }
    }
}

@media (max-width: 768px) {

    .call-to-action {
        .action-content {
            .title {
                font-size: 32px;
                line-height: 42px;
            }

            p {
                br {
                    display: none;
                }
            }
        }
    }

    .call-to-action {
        .scroll-circle {
            max-width: 300px;
        }
    }

    .signup-section {
        .button-container {
            margin-top: 30px;

            &.text-right {
                text-align: left !important;
            }
        }
    }

    .call-to-action-three {
        .text-right {
            margin-top: 20px;
            text-align: left !important;
        }
    }
}