@keyframes zoom {
    from {
        -webkit-transform: scale(1) translate(0px);
    }

    to {
        -webkit-transform: scale(1.03) translate(0px);
    }
}

@keyframes zoommd {
    0% {
        transform: scale(0.9);
    }

    100% {
        transform: scale(1.5);
    }
}

@keyframes zoomin {
    0% {
        transform: scale(0.5);
        opacity: 0;
    }

    50% {
        transform: scale(1.2);
        opacity: 1;
    }

    100% {
        transform: scale(1);
    }
}


@keyframes bounce {
    0% {
        transform: translateY(0);
    }

    100% {
        transform: translateY(40px);
    }
}

@keyframes movexy {
    0% {
        -webkit-transform: translateX(-10px) scale(0.9);
        transform: translateX(-10px) scale(0.9);
    }

    100% {
        -webkit-transform: translateX(30px) scale(1.3) translateY(10px);
        transform: translateX(30px) scale(1.3) translateY(10px);
    }
}

@keyframes wave {
    0% {

        transform: rotateZ(0deg) translate3d(0, 1%, 0) rotateZ(0deg);
    }

    100% {
        transform: rotateZ(360deg) translate3d(0, 1%, 0) rotateZ(-360deg);
    }
}

@keyframes pixFade {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}


@keyframes pixFadeUp {
    0% {
        opacity: 0;
        transform: translateY(20px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes pixFadeDown {
    0% {
        opacity: 0;
        transform: translateY(-20px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}


@keyframes pixFadeLeft {
    0% {
        opacity: 0;
        transform: translateX(20px);
    }

    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes pixFadeRight {
    0% {
        opacity: 0;
        transform: translateX(-20px);
    }

    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes pixZoomIn {
    0% {
        opacity: 0;
        transform: scale(0.7);
    }

    100% {
        opacity: 1;
        transform: scale(1);
    }
}

@keyframes pixBounceIn {
    0% {
        transform: scale(0.1);
        opacity: 0;
    }

    60% {
        transform: scale(1.1);
        opacity: 1;
    }

    100% {
        transform: scale(1);
    }
}

.pixFade {
    animation-name: pixFade;
}

.pixFadeUp {
    animation-name: pixFadeUp;
}

.pixFadeDown {
    animation-name: pixFadeDown;
}

.pixFadeLeft {
    animation-name: pixFadeLeft;
}

.pixFadeRight {
    animation-name: pixFadeRight;
}

.pixZoomIn {
    animation-name: pixZoomIn;
}

.pixBounceIn {
    animation-name: pixBounceIn;
}

.zoomIn {
    animation-name: zoomin;
}

@keyframes jumping {
    0% {
        transform: translateY(0);
        opacity: 0;
    }

    50% {
        transform: translateY(-20px);
        opacity: 1;
    }

    100% {
        transform: translateY(0);
        opacity: 0;
    }
}

@keyframes jump {
    0% {
        transform: translateY(5px);

    }

    50% {
        transform: translateY(-10px);

    }

    100% {
        transform: translateY(5px);

    }
}

@keyframes stickySlideDown {
    from {
        -webkit-transform: translateY(-100%);
        transform: translateY(-100%)
    }

    to {
        -webkit-transform: translateY(0);
        transform: translateY(0)
    }
}

// @keyframes moveclouds {
//     0% {
//         margin-left: 1000px;
//     }
// 	100% {
//         margin-left: -1000px;
//     }
// }

@keyframes moveclouds {
    0% {
        transform: translate3d(-10px, -10px, 0);
        opacity: 1;
    }

    50% {
        transform: translate3d(-20px, 20px, 0);
        opacity: 1;
    }

    100% {
        transform: translate3d(30px, 0px, 0);
        opacity: 1;
    }
}


@keyframes pulse {
    0% {
        width: 82px;
        height: 82px;
        opacity: 1;
    }

    50% {
        opacity: 1;
    }

    100% {
        height: 150px;
        width: 150px;
        opacity: 0;
    }
}

@keyframes animationFramesLeft {
    0% {
        transform: translate(0px, 0px) rotate(0deg);
    }

    20% {
        transform: translate(-73px, 1px) rotate(-36deg);
    }

    40% {
        transform: translate(-141px, -72px) rotate(-72deg);
    }

    60% {
        transform: translate(-83px, -122px) rotate(-108deg);
    }

    80% {
        transform: translate(40px, -72px) rotate(-144deg);
    }

    100% {
        transform: translate(0px, 0px) rotate(0deg);
    }
}

@keyframes animationFramesRight {
    0% {
        transform: translate(0px, 0px) rotate(0deg);
    }

    20% {
        transform: translate(73px, 1px) rotate(36deg);
    }

    40% {
        transform: translate(141px, 72px) rotate(72deg);
    }

    60% {
        transform: translate(83px, 122px) rotate(108deg);
    }

    80% {
        transform: translate(-40px, 72px) rotate(144deg);
    }

    100% {
        transform: translate(0px, 0px) rotate(0deg);
    }
}

@keyframes wave {
    0% {
        transform: rotateZ(0deg) translate3d(0, 3%, 0) rotateZ(0deg);
    }

    100% {

        transform: rotateZ(360deg) translate3d(0, 3%, 0) rotateZ(-360deg);
    }
}

@keyframes waveRote {
    0% {
        transform: rotateZ(0deg) rotate(0deg) translate3d(0, 1%, 0) rotateZ(0deg);
    }

    100% {
        transform: rotateZ(360deg) rotate(45deg) translate3d(0, 1%, 0) rotateZ(-360deg);
    }
}













@keyframes animateRight {
    49% {
        transform: translateX(100%)
    }
    50% {
        opacity: 0;
        transform: translateX(-100%)
    }
    51% {
        opacity: 1
    }
}

@keyframes animationFramesOne {
    0% {
        transform: translate(0px, 0px) rotate(0deg)
    }
    20% {
        transform: translate(73px, -1px) rotate(36deg)
    }
    40% {
        transform: translate(141px, 72px) rotate(72deg)
    }
    60% {
        transform: translate(83px, 122px) rotate(108deg)
    }
    80% {
        transform: translate(-40px, 72px) rotate(144deg)
    }
    100% {
        transform: translate(0px, 0px) rotate(0deg)
    }
}



@keyframes animationFramesTwo {
    0% {
        transform: translate(0px, 0px) rotate(0deg) scale(1)
    }
    20% {
        transform: translate(73px, -1px) rotate(36deg) scale(.9)
    }
    40% {
        transform: translate(141px, 72px) rotate(72deg) scale(1)
    }
    60% {
        transform: translate(83px, 122px) rotate(108deg) scale(1.2)
    }
    80% {
        transform: translate(-40px, 72px) rotate(144deg) scale(1.1)
    }
    100% {
        transform: translate(0px, 0px) rotate(0deg) scale(1)
    }
}


@keyframes animationFramesThree {
    0% {
        transform: translate(165px, -179px)
    }
    100% {
        transform: translate(-346px, 617px)
    }
}


@keyframes animationFramesFour {
    0% {
        transform: translate(-300px, 151px) rotate(0deg)
    }
    100% {
        transform: translate(251px, -200px) rotate(180deg)
    }
}


@keyframes animationFramesFive {
    0% {
        transform: translate(61px, -99px) rotate(0deg)
    }
    21% {
        transform: translate(4px, -190px) rotate(38deg)
    }
    41% {
        transform: translate(-139px, -200px) rotate(74deg)
    }
    60% {
        transform: translate(-263px, -164px) rotate(108deg)
    }
    80% {
        transform: translate(-195px, -49px) rotate(144deg)
    }
    100% {
        transform: translate(-1px, 0px) rotate(180deg)
    }
}







